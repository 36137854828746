import { FC } from 'react'

const Blog: FC = () => {
    return (
        <div>

        </div>
    )
}

export default Blog
