import React, { useEffect, useState } from 'react';
import './ProductCardStyle.css'
import { Link } from 'react-router-dom';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { useApiRequest } from '../../helpers/ApiRequest';
import { TruncateText, productImgUrl, variantIconUrl, variantImgUrl } from '../../helpers/Helpers';
import { ErrorNotify } from '../../helpers/Toastify';
import { MdShoppingCart, MdOutlineFavorite } from 'react-icons/md'
import DottedLoading from '../Loading/DottedLoading';
import { useWishlist } from '../../contexts/WishlistContext';
import { AuthUserProps, ProductProps, VariantProps } from '../../helpers/Props';

interface ProductCardProps {
  item: ProductProps;
  openModal?: (product: any) => void;
  isGrid?: boolean;
  productIdsObj: {
    productIds: number[];
    isProductIds: boolean;
  };
};

const ProductCard: React.FC<ProductCardProps> = ({ productIdsObj, item, isGrid }) => {

  // Global
  const { addToCart } = useShoppingCart();
  const { addToWishlist } = useWishlist();
  const apiRequest = useApiRequest();
  const [discount, setDiscount] = useState(null);
  const [isGetDiscount, setIsGetDiscount] = useState(false);
  const [haveDiscount, setHaveDiscount] = useState(false);
  const [price, setPrice] = useState<number>(item.sellingPrice);

  // Selected Variant
  const [selectedVariant, setSelectedVariant] = useState<any>('');
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const [selectedVariantImage, setSelectedVariantImage] = useState<string>();

  // User
  const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
  const storedUser = localStorage.getItem('user');

  useEffect(() => {
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser));
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsGetDiscount(false)
      if (productIdsObj.isProductIds) {
        if (productIdsObj.productIds.length > 0) {
          if (productIdsObj.productIds.includes(item.id)) {

            const response = await apiRequest({
              route: `promotion-discount/product_id/${item.id}`,
              method: 'GET',
            });

            if (response.status === 200) {
              setDiscount(response.data.discount)
              setIsGetDiscount(true)
              if (response.data.discount !== null) {
                setHaveDiscount(true)
                setPrice(item.sellingPrice - (item.sellingPrice * (response.data.discount / 100)))
              }
              else {
                setPrice(item.sellingPrice)
              }
            } else {
              setIsGetDiscount(false)
              setHaveDiscount(false)
              ErrorNotify('Something wrong, try again')
            }
          }// End check includes if
          else {
            setIsGetDiscount(true)
            setHaveDiscount(false)
            setPrice(item.sellingPrice)
          }
        } else {
          setIsGetDiscount(true)
        }
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    fetchData();
    if (item.variant && item.variant?.length > 0) {
      setSelectedVariant(`${item.variant[0].reference} ${item.variant[0].label && item.variant[0].label}`)
      setSelectedVariantId(item.variant[0].id)
      setSelectedVariantImage(item.variant[0].image)
    }
  }, [item.id, productIdsObj.productIds, haveDiscount])


  return (
    <div className={`col-md-6 col-lg-6 col-xl-4 col-xxl-3 col-sm-6 p-0 ${isGrid ? 'col-6 isgrid' : 'col-12 isrow'} product-item`} key={item.slug}>
      <div className="container">
        <div className="row d-flex-center">
          <div className="product-image text-center dflex justify-content-center">

            {haveDiscount
              ?
              <span className='position-fixed discount-value '>{`-${discount} %`}</span>
              :
              ''
            }

            <div className="view-details position-fixed c-pointer">
              <div className="eye d-flex-center text-center">
                <div className="view-details-content d-flex-center c-pointer" onClick={() => addToWishlist(authUser?.id, item.id, price, 'product')}>
                  <MdOutlineFavorite className="view-details-eye-icon" />
                </div>
              </div>
            </div>
            <Link to={`/produit/${item.slug}`}>
              <img alt={item.title} src={`${selectedVariantImage ? variantImgUrl : productImgUrl}/${selectedVariantImage ? selectedVariantImage : item.image}`} className="product-img mb-5" />
            </Link>
          </div>
          <div className="text-start">
            <div className="product-details text-start">
              <div className={`product-info`}>
                <Link className='nav-link' to={`/produit/${item.slug}`}>
                  <div className="product-title text-cap"><TruncateText text={item.title} maxLength={55} /></div>
                </Link>

                {item.variant && item.variant?.length > 0 ?
                  <div className={`row variant-row ${selectedVariant ? 'variant-row-m2' : 'variant-row-m1'}`}>
                    <div className="variant-ref-content pb-1 ps-0">
                      {selectedVariant ?
                        <span className='ff-3 clr-var'>{selectedVariant}</span>
                        :
                        null}
                    </div>
                    {item.variant?.map((v: VariantProps) => {
                      const sv = `${v.reference && v.reference} ${v.label && v.label}`
                      return (
                        <div
                          key={v.id}
                          className={`variant-icon-content mx-1 d-flex-center c-pointer ${selectedVariant === sv ? 'selected-border' : 'notselected-border'}`}
                          onClick={() => {
                            setSelectedVariant(`${v.reference && v.reference} ${v.label && v.label}`)
                            setSelectedVariantId(v.id)
                            setSelectedVariantImage(v.image)
                            // console.log(item.image);
                          }}>

                          <div className="variant-icon">
                            <img src={`${variantIconUrl}/${v.icon}`} alt={v.label} className='variant-icon-img' />
                          </div>
                        </div>
                      )
                    })
                    }
                  </div>
                  :
                  null
                }

                <div className="add-to-card-container fixed-bottom row justify-content-between align-items-center mb-1">
                  <hr className='hr-tag m-hr' />
                  <div className="col-8 text-start">
                    <div className="product-price fixedbottom ff-0 fw-600">
                      {haveDiscount
                        ?
                        <>
                          <p className='m-0'><span className='clr-orange'>{price} DH</span></p>
                          <p className='p-discount-price'><del className='me-2 discount-price ff-0 fw-600'>{item.sellingPrice} DH</del></p>
                        </>
                        :
                        <span>{price} DH</span>
                      }
                    </div>
                  </div>

                  <div className="col-4 text-end shopping-cart-add-to d-flex justify-content-end ">
                    {isGetDiscount ?
                      <div
                        className="shopping-cart-icon-parent d-flex-center c-pointer"
                        onClick={() => {
                          const variantId = selectedVariantId ? selectedVariantId : '';
                          if (item.variant && item.variant?.length > 0) {
                            addToCart(item.id, price, 'product', variantId, 1, item.sellingPrice, true);
                          } else {
                            addToCart(item.id, price, 'product', '', 1, item.sellingPrice);
                          }
                          // if (item.variant && item.variant?.length > 0) {
                          //   addToCart(item.id, price, 'product', selectedVariantId, 1, item.sellingPrice)
                          // } else {
                          //   addToCart(item.id, price, 'product', '', 1, item.sellingPrice)
                          // }
                        }}
                      >
                        <MdShoppingCart className='shopping-cart-icon' />
                      </div>
                      :
                      <div className="loading-discount">
                        <DottedLoading />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;