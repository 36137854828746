import React, { useState, memo, useEffect } from 'react'
import AnimatedLoading from '../../components/Loading/AnimatedLoading'
import ProductImagesGallery from '../../components/Carousel/ProductImagesGallery/ProductImagesGallery'

import { useParams } from 'react-router-dom'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import ShareButtons from '../../components/ShareButtons/ShareButtons'
import ReviewForm from '../../components/Reviews/ReviewForm'
import { Helmet } from 'react-helmet-async'
import { useApiRequest } from '../../helpers/ApiRequest'
import { ProductProps, VariantProps } from '../../helpers/Props'
import { Breadcrumbs } from '@mui/material'
import { emphasize, styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import { FrCustomeErrorNorify } from '../../helpers/Toastify'
import '../ProductDetails/ProductDetailsStyle.css'
import ControlledAccordions from '../../components/ControlledAccordions/ControlledAccordions'
import { DecodeData, productImgUrl, variantIconUrl } from '../../helpers/Helpers'
import Icons from '../../helpers/IconsHelper'
import { Navbar, TopHeader, Footer, contexts } from '../../helpers/Index'

const ProductDetails: React.FC = () => {
    const { slug } = useParams()
    const [cardItemQuantity, setCardItemQuantity] = useState(1)
    const { addToCart } = contexts.useShoppingCart()
    const apiRequest = useApiRequest()
    const [discount, setDiscount] = useState(null)
    const [product, setProduct] = useState<ProductProps>()
    const [selectedVariant, setSelectedVariant] = useState<any>('')
    const [selectedVariantImage, setSelectedVariantImage] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)


    const fetchProductData = async () => {
        try {
            setIsLoading(true)
            const response = await apiRequest({
                route: `products.json?slug=${slug}`,
                method: 'GET',
            })

            if (response.status === 200) {
                setProduct(response.data[0])
                setIsLoading(false)
            } else {
                FrCustomeErrorNorify()
                setIsLoading(false)
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }

    const fetchPromotionData = async () => {
        try {

            if (product?.id) {
                const response = await apiRequest({
                    route: `promotion-discount/product_id/${product?.id}`,
                    method: 'GET',
                })

                if (response.status === 200) {
                    setDiscount(response.data.discount)
                } else {
                    FrCustomeErrorNorify()
                }
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProductData()
    }, [])

    useEffect(() => {
        fetchPromotionData()
    }, [product?.id])

    useEffect(() => {
        if (product && product.variant && product.variant?.length > 0) {
            setSelectedVariant(`${product.variant[0].reference} ${product.variant[0].label && product.variant[0].label}`)
        }
    }, [product])

    const increment = () => {
        setCardItemQuantity(prevItem => prevItem + 1)
    }

    const decrement = () => {
        if (cardItemQuantity > 1) {
            setCardItemQuantity(prevItem => prevItem - 1)
        }
    }

    if (!product) {
        return null
    }

    let productPrice = discount !== null ? (product.sellingPrice - (product.sellingPrice * (discount / 100))) : product.sellingPrice

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        const backgroundColor =
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[800]
        return {
            backgroundColor,
            height: theme.spacing(3),
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
        }
    }) as typeof Chip


    return (

        <>
            <Helmet>
                <title>Beauty Hub - {product.title}</title>
            </Helmet>
            <TopHeader />
            <Navbar />

            {isLoading ?
                <div className="product-details mt-3 mx-3">
                    <AnimatedLoading label='de produit' />
                </div>
                :
                <>
                    <div className="product-details mt-5 pt-5 mb-5 ox-hidden">
                        <div className="container">
                            <div className="row product-details-content d-flex justify-content-around">
                                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                                    <div className={`product-image-gallery ${product.productImages.length < 1 ? 'd-flex justify-content-center' : ''}`}>
                                        {product.productImages && product.productImages.length > 0 && selectedVariantImage.length === 0 ?
                                            <ProductImagesGallery product={product} />
                                            : selectedVariantImage && product.variant[0].variantImages && product.variant[0].variantImages.length > 0 ?
                                                <ProductImagesGallery product={product} isVariant={true} index={selectedIndex} />
                                                :
                                                <img src={`${productImgUrl}/${product.image}`}
                                                    alt={product.title}
                                                    className="img-fluid product-details-image d-flex justify-content-center" />
                                        }
                                    </div>
                                    {product.video_url && (
                                        <div className="product-video mt-5 d-non">
                                            <div className="video-container">
                                                <iframe
                                                    id="player"
                                                    src={product.video_url.replace('watch?v=', 'embed/')}
                                                    allowFullScreen
                                                    title={product.title}
                                                ></iframe>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-2 ps-3 ps-sm-1">

                                    {/* --------- Start Breadcrumbs */}
                                    <Breadcrumbs aria-label="breadcrumb" className=''>
                                        <StyledBreadcrumb
                                            component="a"
                                            href="/"
                                            label="Accueil"
                                            icon={<Icons.AiFillHome fontSize="small" />}
                                        />
                                        <StyledBreadcrumb
                                            component="a"
                                            href={`/boutique/categorie/${product.categorie[0].slug}`}
                                            label={`${product.categorie[0].title}`}
                                        />
                                        {
                                            product.categorie[1] ?
                                                <StyledBreadcrumb
                                                    component="a"
                                                    href={`/boutique/categorie/${product.categorie[0].slug}/${product.categorie[1]?.slug}`}
                                                    label={`${product.categorie[1]?.title}`}
                                                />
                                                :
                                                ''
                                        }


                                        {/* {product.categorie.length > 1 ? ( */}

                                        {/* ) : ''} */}
                                    </Breadcrumbs>
                                    {/* --------- Start Breadcrumbs */}

                                    <div className="product-details-info">
                                        <h1 className='product-details-title text-cap'>{product.title}</h1>
                                        {productPrice !== product.sellingPrice
                                            ?
                                            <>
                                                <del className='me-2 discount-price mt-3'>{product.sellingPrice}DH</del>
                                                <p className="product-details-prix mt-1 mb-5 clr-pink">{productPrice} DH</p>
                                            </>
                                            :
                                            <p className="product-details-prix mt-3 mb-5">{productPrice} DH</p>
                                        }

                                        <div className="product-details-total">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-6 product-details-total-text">
                                                    Prix total :
                                                </div>
                                                <div className=" col-lg-6 col-md-6 col-6 product-details-total-price">
                                                    {
                                                        cardItemQuantity > 0 ?
                                                            `${productPrice * Number(cardItemQuantity)} DH`
                                                            :
                                                            `${productPrice} DH`
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='hr-tag' />

                                        {/* --------- Start Variant */}
                                        {product.variant && product.variant?.length > 0 ?
                                            <div className={`row variant-row ${selectedVariant ? 'variant-row-m2' : 'variant-row-m1'}`}>
                                                <div className="variant-ref-content pb-1 ps-0">
                                                    {selectedVariant ?
                                                        <span className='ff-3 clr-var'>{selectedVariant}</span>
                                                        :
                                                        null}
                                                </div>
                                                {product.variant?.map((v: VariantProps, index: number) => {
                                                    const sv = `${v.reference && v.reference} ${v.label && v.label}`
                                                    return (
                                                        <div className={`variant-icon-content mx-1 d-flex-center c-pointer ${selectedVariant === sv ? 'selected-border' : 'notselected-border'}`}
                                                            key={v.id}
                                                            onClick={() => {
                                                                setSelectedVariant(`${v.reference && v.reference} ${v.label && v.label}`)
                                                                setSelectedVariantImage(v.variantImages)
                                                                setSelectedIndex(index)
                                                            }}>
                                                            <div className="variant-icon">
                                                                <img src={`${variantIconUrl}/${v.icon}`} alt="" className='variant-icon-img' />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                        {/* --------- End Variant */}


                                        {/* --------- Start Add To Cart */}
                                        <div className="row align-items-center mt-5">
                                            <div className="col-md-4 col-lg-4 col-xl-4 col-12 mb-">
                                                <div className="shopping-card-item-quantity align-items-center text-center">
                                                    <span className="align-items-center fs-4" onClick={() => decrement()}>
                                                        <Icons.HiMinusSm className='shopping-card-item-quantity-icon' />
                                                    </span>
                                                    <span className="user-select-none align-items-center px-3">{cardItemQuantity}</span>
                                                    <span className="align-items-center fs-4" onClick={() => increment()}>
                                                        <Icons.BiPlus className='shopping-card-item-quantity-icon' />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-lg-8 col-xl-8 col-12">
                                                <button onClick={() => addToCart(product.id, productPrice, 'product', cardItemQuantity)} type="submit" className="d-none btn addtocard-btn">
                                                    <Icons.FiShoppingCart className='modal-card-icon' />Ajouter au panier
                                                </button>
                                                <SubmitButton
                                                    btnLabel="Ajouter au panier"
                                                    content={<Icons.FiShoppingCart className='addtocart-icon' />}
                                                    onClick={() => addToCart(product.id, productPrice, 'product', cardItemQuantity)}
                                                    className="mt-1" />
                                            </div>

                                        </div>
                                        {/* --------- End Add To Cart */}


                                        {/* --------- Start Description */}
                                        {
                                            product.description || product.howToUse || product.ingredients ?
                                                <hr className='hr-tag' />
                                                :
                                                null
                                        }

                                        {
                                            product.description && product.description !== null ?
                                                <ControlledAccordions
                                                    id='description'
                                                    title={`Description`}
                                                    content={<DecodeData content={product.description} />}
                                                />
                                                :
                                                null
                                        }

                                        {
                                            product.howToUse && product.howToUse !== null ?
                                                <ControlledAccordions
                                                    id='howtouse'
                                                    title={`Conseils d'Utilisation`}
                                                    content={<DecodeData content={product.howToUse} />}
                                                />
                                                :
                                                null
                                        }

                                        {
                                            product.ingredients && product.ingredients !== null ?
                                                <ControlledAccordions
                                                    id='ingredients'
                                                    title={`Ingredients`}
                                                    content={<DecodeData content={product.ingredients} />}
                                                />
                                                :
                                                null
                                        }
                                        {/* --------- End Description */}

                                        <hr className='hr-tag' />
                                        <ShareButtons product={product} entity={'product'} />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="container mb-5 pb-5 desc-section">
                            <hr className='hr-tag' />
                            <div className="row mt-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                    <ReviewForm productId={product.id} isPack={false} />
                                </div>

                            </div>
                        </div>
                    </div >
                    <Footer />
                </>
            }
        </>
    )
}

export default memo(ProductDetails)