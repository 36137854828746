import { lazy, FC, Suspense, useEffect, useState } from 'react';
import Loading from '../components/Loading/Loading';

const withLoading = (WrappedComponent: FC) => {
  return () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }, []);

    return (
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            <WrappedComponent />
          </Suspense>
        )}
      </div>
    );
  };
};

export const Boutique = withLoading(lazy(() => import('../pages/Boutique/Pages/Product/Boutique')));
export const PackBoutique = withLoading(lazy(() => import('../pages/Boutique/Pages/Pack/PackBoutique')));
export const PromotionBoutique = withLoading(lazy(() => import('../pages/Boutique/Pages/Promotion/PromotionBoutique')));
export const BestSellerBoutique = withLoading(lazy(() => import('../pages/Boutique/Pages/BestSeller/BestSellerBoutique')));
export const ProductDetails = withLoading(lazy(() => import('../pages/ProductDetails/ProductDetails')));
export const PackDetails = withLoading(lazy(() => import('../pages/PackDetails/PackDetails')));