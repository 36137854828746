import { toast } from 'react-toastify';

export function notify (text:string) {
    toast.success(`${text}`);
};

export function ErrorNotify (text:string) {
    toast.error(`${text}`);
};

export function InfoNotify (text:string) {
    toast.info(`${text}`);
};

export function AddingNotify (entity:string) {
    toast.success(`${entity} ajouté avec succès`);
};

export function UpdateNotify (entity:string) {
    toast.success(`${entity} modifié avec succès`);
};

export function DeleteNotify (entity:string) {
    toast.success(`${entity} supprimé avec succès`);
};

export function EnCustomeErrorNorify () {
    toast.error('Something wrong, try again');
};

export function FrCustomeErrorNorify () {
    toast.error('Une erreur est survenue, réessayez');
};