import React, { memo, useCallback, useEffect, useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';
import { HiMinusSm } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useApiRequest } from '../../helpers/ApiRequest';
import { CartItemProps } from '../../helpers/Props';
import { useWishlist } from '../../contexts/WishlistContext';
import { useWishlistDataContext } from '../../contexts/WishlistDataContext';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import './WishlistItem.css'
import { uploadUrl } from '../../helpers/Helpers';

const WishlistItem: React.FC<CartItemProps> = ({ product, quantity }) => {

  const {
    removeFromWishlist,
    incrementWishlistQuantity,
    decrementWishlistQuantity,
    wishlistItems
  } = useWishlist()

  const { addToCart } = useShoppingCart()
  const { products, packs } = useWishlistDataContext();
  const [discount, setDiscount] = useState(null);
  const apiRequest = useApiRequest();


  const mergedArray = [...products, ...packs];

  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)

  const fetchPromotionData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `promotion-discount/product_id/${item.id}`,
          method: 'GET',
        });

        setDiscount(response.data.discount)
      }

    } catch (error) {

    }
  }, [item?.id]);


  useEffect(() => {
    fetchPromotionData();
  }, [item?.id])

  if (item == null) return null

  let productPrice = discount !== null ? (item.sellingPrice - (item.sellingPrice * (discount / 100))) : item.sellingPrice;


  return (
    <>
      <div className="wishlist-item d-flex-ai-center">
        <div className="container">
          <div className="row d-flex-center wishlist-item-content">
            <div className="col-lg-3 col-md-2 col-sm-5 col-5 d-flex-ai-center wishlist-item-image">
              <Link to={`${item.itemType === 'pack' ? `/boutique/pack/${item.slug}` : `/produit/${item.slug}`}`} className='d-inline-flex h-100'>
                <img src={`${uploadUrl}/${item.itemType === 'pack' ? 'pack' : 'product'}_images/${item.image}`} alt={item.title} className="rounded wishlist-item-img" />
              </Link>
            </div>

            <div className="col-lg-6 col-md-5 col-sm-7 col-7 d-flex-ai-center">
              <div className="wishlist-item-details">
                <div className="wishlist-item-title mb-2 ff-0 fw-600">{item.title}</div>
                <div className="wishlist-item-prix clr-dark">{item.itemType === 'pack' ? item.sellingPrice.toFixed(2) : productPrice.toFixed(2)} DH</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-3 d-flex-ai-center">
              <div className="wishlist-item-action">
                <button
                  className='btn my-1 clr-info fw-600 ff-0 text-center wishlist-item-btn wishlist-item-addtocart'
                  onClick={() => {
                    item.itemType === 'product' ?
                      addToCart(item.id, item.sellingPrice, 'product')
                      :
                      addToCart(item.uniqId, item.sellingPrice, 'pack')
                  }}>
                  Ajouter au panier
                </button>
                <button
                  className='btn my-1 clr-pink fw-600 ff-0 text-center wishlist-item-btn wishlist-item-delete'
                  onClick={(() => removeFromWishlist(item.itemType === 'pack' ? item.uniqId : item.id))}>
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MemoizedWishlistItem = memo(WishlistItem);

export { MemoizedWishlistItem as WishlistItem };