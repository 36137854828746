import { useState, useEffect } from 'react';
import { useApiRequest } from '../helpers/ApiRequest';
import { ProductInfoProps } from '../helpers/Props';
import { FrCustomeErrorNorify } from '../helpers/Toastify';

export const useProductInfo = (url: string): ProductInfoProps => {
  const apiRequest = useApiRequest();
  const [totalProducts, setTotalProducts] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const getProductInfo = async () => {
    try {
      const response = await apiRequest({
        route: url,
        method: 'GET',
      });

      if (response.status === 200) {
        setTotalProducts(response.data.total_products.totalProducts);
        setMinPrice(response.data.total_products.minPrice);
        setMaxPrice(response.data.total_products.maxPrice);
      }
    } catch (error) {
      FrCustomeErrorNorify();
    }
  };

  useEffect(() => {
    getProductInfo();
  }, [url]);

  return { minPrice, maxPrice, totalProducts };
};
