import { motion } from 'framer-motion';
import { FiShoppingCart } from 'react-icons/fi';
import ProductSlider from '../Carousel/DetailsProductSlider/ProductSlider';
// import SubmitButton from '../submitButton/SubmitButton';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { Link } from 'react-router-dom';
import { useApiRequest } from '../../helpers/ApiRequest';
import { useCallback, useEffect, useState } from 'react';
import './ModalContent.css';
import { ErrorNotify } from '../../helpers/Toastify';
import DottedLoading from '../Loading/DottedLoading';
import { productImgUrl } from '../../helpers/Helpers';
type ModalProps = {
  show: boolean;
  product: any;
  onClose: () => void;
};

const Modal: React.FC<ModalProps> = ({ show, product, onClose }) => {
  const { addToCart } = useShoppingCart();
  const apiRequest = useApiRequest();
  const [discount, setDiscount] = useState(null);
  const [isGetDiscount, setIsGetDiscount] = useState(false);
  const fetchData = useCallback(async () => {
    try {

      if (product.id) {
        const response = await apiRequest({
          route: `promotion-discount/product_id/${product.id}`,
          method: 'GET',
        });

        if (response.status === 200) {
          setDiscount(response.data.discount)
          setIsGetDiscount(true)
        } else {
          setIsGetDiscount(false)
          ErrorNotify('Something wrong, try again')
        }
      }

    } catch (error) {
      
    }
  }, [product.id]);


  useEffect(() => {
    fetchData();
  }, [product.id])


  let productPrice = discount !== null ? (product.sellingPrice - (product.sellingPrice * (discount / 100))) : product.sellingPrice;


  if (!show) return null;

  return (
    <motion.div
      className="modal fade show"
      tabIndex={-1}
      role="dialog"
      style={{ display: 'block' }}
      initial={{ scale: 0.2 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.00001 }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg d-flex justify-content-center">
        <div className="modal-content">
          <div className="modal-header text-end">
            <button type="button" className="close btn modal-close-btn" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            <div className="row align-itemscenter d-flex justify-content-center modal-product-slider">
              <div className="col-xl-6 col-lg-6 col-md-5 col-sm-8 col-11 d-flex justify-content-center">
                {
                  product.productImages.length > 0 ?
                    <ProductSlider items={product.productImages} />
                    :
                    <img src={`${productImgUrl}/${product.image}`}
                      alt={product.title}
                      className="img-fluid modal-image" />
                }
              </div>
              <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 col-desc"
              >
                <h2 className="modal-product-title"><Link className='nav-link' to={`/produit/${product.slug}`}>{product.title}</Link></h2>
                {/* <p className="modal-product-prix">{productPrice} DH</p> */}
                {productPrice !== product.sellingPrice
                  ?
                  <>
                    <del className='me-2 discount-price'>{product.sellingPrice}DH</del>
                    <p className="modal-product-prix">{productPrice} DH</p>
                  </>
                  :
                  <p className="modal-product-prix">{productPrice} DH</p>
                }
                {
                  product.description ?
                    <p className="modal-product-desc" dangerouslySetInnerHTML={{ __html: product.description.replaceAll('\\n', "<br>") }}></p>
                    :
                    ''
                }
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {
              isGetDiscount ?
                <button type="button" onClick={() => addToCart(product.id, productPrice, 'product')} className="btn addtocard-btn">
                  <FiShoppingCart className="modal-card-icon" />Ajouter au panier
                </button>
                :
                <div className="loading-discount">
                  <DottedLoading />
                </div>
            }
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Modal;
