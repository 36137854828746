import React, { useEffect, useState } from 'react'
import ReviewItem from './ReviewItem'
import { Rating } from 'react-simple-star-rating'
import FormInput from '../FormInput/FormInput'
import SubmitButton from '../SubmitButton/SubmitButton'
import { useFormik } from 'formik'
import * as yup from "yup"
import { AuthUserProps, ReviewsProps } from '../../helpers/Props'
import { useApiRequest } from '../../helpers/ApiRequest'
import { CustomeRating, FormatDate, calculateReviewsByRating, calculateTotalRating } from '../../helpers/Helpers'
import { InfoNotify, ErrorNotify, notify } from '../../helpers/Toastify'
import { useNavigate } from 'react-router-dom'
import DottedLoading from '../Loading/DottedLoading'
import './ReviewsStyle.css'

interface ReviewFormProps {
  productId?: number
  isPack?: boolean
}

const ReviewForm: React.FC<ReviewFormProps> = ({ productId, isPack }) => {

  const [authUser, setAuthUser] = useState<AuthUserProps | null>(null)
  const storedUser = localStorage.getItem('user')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser))
    }
  }, [])

  const [productReviews, setProductReviews] = useState<ReviewsProps[]>([])
  const [ReviewsCountCheck, setReviewsCountCheck] = useState(false)
  const [ReviewsCount, setReviewsCount] = useState(0)
  const [ReviewsTotal, setReviewsTotal] = useState(0)
  const apiRequest = useApiRequest()


  const status = 'pending'
  const product = `api/products/${productId}`
  const pack = `api/packs/${productId}`
  const user = `api/users/${authUser?.id}`

  const validationSchema = yup.object({
    message: yup.string().required("Veuillez saisir votre avis"),
    rating: yup.number().required("Veuillez donner une note"),
  })

  const formik = useFormik({
    initialValues: {
      message: "",
      rating: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data

      isPack
        ? data = { ...values, pack, user, dateCreate: FormatDate(new Date()), status }
        : data = { ...values, product, user, dateCreate: FormatDate(new Date()), status }

      try {

        if (authUser?.id) {
          setIsLoading(true)
          const route = isPack
            ? `check-pack-reviews/pack/${productId}/user/${authUser?.id}`
            : `check-product-reviews/product/${productId}/user/${authUser?.id}`

          const response = await apiRequest({
            route: route,
            method: 'GET',
          })

          if (response.status === 200) {
            if (response.data.message === 'Not Found') {
              const response = await apiRequest({
                route: `user/check-user-info/${authUser?.id}`,
                method: 'GET',
              })

              if (response.status === 200) {
                if (response.data.result === 'true') {
                  const response = await apiRequest({
                    route: `reviews`,
                    method: 'POST',
                    body: data
                  })

                  if (response.status === 201) {
                    notify('Avis bien envoyé')
                    setIsLoading(false)
                  }
                }
                else {
                  navigate('/my-account/edit-account')
                  InfoNotify('Vous devez ajouter vos informations de compte')
                }
              }

            } else {
              ErrorNotify('Vous avez déjà envoyé votre avis')
              setIsLoading(false)
            }
          }
        }

      } catch (error) {
        ErrorNotify('Something wrong, try again')
        setIsLoading(false)
      }


    },
  })

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik

  const route = isPack
    ? `reviews/pack_id/${productId}`
    : `reviews/product_id/${productId}`

  const fetchReviews = async () => {
    try {
      const response = await apiRequest({
        route: route,
        method: 'GET',
      })

      if (response.status === 200) {
        setProductReviews(response.data.reviews)
        if (response.data.reviews.length > 0) {
          setReviewsCount(response.data.reviews.length)
          setReviewsCountCheck(true)
          setReviewsTotal(calculateTotalRating(response.data.reviews))

        }
      } else {
        ErrorNotify('Something wrong, try again')
      }

    } catch (error) {
      // ErrorNotify('Something wrong, try again')
    }
  }

  useEffect(() => {
    fetchReviews()
  }, [])
  // console.log(ReviewsTotal);

  return (
    <div className="review-form mt-5">
      <div className="row">
        <div className="col-12 px-0">
          {

            ReviewsCountCheck === true ?
              <>
                <h2 className="text-center d-flex-center text-blue reviews-title fw-bold mb-5 mt-2">Avis ({ReviewsCount})</h2>
                <div className="row px-0">
                  <div className="col-xl-2 col-md-4 col-6 ps-0 d-flexd total-reviews">
                    <div className="d-flex ai-center ps-0">
                      <span className='avg-rating clr-blue fs-3 d-inline-flexflex-wrapw-100 pe-1'>{ReviewsTotal}.0</span>
                      <Rating
                        initialValue={ReviewsTotal}
                        readonly
                      />
                    </div>
                    <p className='d-inline-flexflex-wrapw-100 fs-5 clr-blue'>Basé sur {ReviewsCount} avis</p>
                  </div>

                  <div className="col-6">
                    <div className="ps-0 col-12 d-flex-center flex-wrap reviews-by-rating">
                      <div className="d-inlin w-100  ">
                        <CustomeRating
                          initValue={1}
                          readonly
                        /> <span className='clr-blue'>({calculateReviewsByRating(productReviews, 1)})</span>
                      </div>
                      <div className="d-inlin w-100">
                        <CustomeRating
                          initValue={2}
                          readonly
                        /> <span className='clr-blue'>({calculateReviewsByRating(productReviews, 2)})</span>
                      </div>
                      <div className="d-inlin w-100">
                        <CustomeRating
                          initValue={3}
                          readonly
                        /> <span className='clr-blue'>({calculateReviewsByRating(productReviews, 3)})</span>
                      </div>
                      <div className="d-inlin w-100">
                        <CustomeRating
                          initValue={4}
                          readonly
                        /> <span className='clr-blue'>({calculateReviewsByRating(productReviews, 4)})</span>
                      </div>
                      <div className="d-inlin w-100">
                        <CustomeRating
                          initValue={5}
                          readonly
                        /> <span className='clr-blue'>({calculateReviewsByRating(productReviews, 5)})</span>
                      </div>
                    </div>
                  </div>

                  <hr className='hr-tag' />
                </div>
                {
                  productReviews.map(review => (
                    <ReviewItem key={review.id}
                      name={`${review.user.nom} ${review.user.prenom}`}
                      comment={review.message}
                      rating={review.rating}
                    />
                  ))
                }
              </>
              :
              ''
          }
        </div>

      </div>

      <div className="row">
        <div className="col-12"></div>
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <h3 className="text-start text-blue fw-bold mb-5 mt-">Ajouter un Avis</h3>
          </div>

          <div className="col-12 mb-4">
            <p>Votre Note *</p>
            <div className="rating">
              <Rating
                onClick={(rate) =>
                  handleChange({ target: { name: 'rating', value: rate } })
                }
                initialValue={0}
              />
              {touched.rating && errors.rating && (
                <div className="error-message">{errors.rating}</div>
              )}
            </div>
          </div>

          <div className="col-xl-5 col-lg-7 col-md-10 col-12 mb-4">
            <FormInput
              label="Votre avis *"
              spanValue="Votre avis"
              name="message"
              textarea={true}
              rows={6}
              value={values.message}
              className={touched.message && errors.message ? 'is-invalid' : ''}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-12 review-box-btn mt-2">
            {
              isLoading ?
                <DottedLoading />
                :
                <SubmitButton btnLabel="Soumettre" className="mt-1 mb-5" />
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReviewForm