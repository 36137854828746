export const initLogin = {
    email: "",
    password: "",
}

export const initRegister = {
    nom: "",
    prenom: "",
    telephone: "",
    whatsapp: "",
    email: "",
    password: "",
    // confirmPassword: "",
}

export const initUserAccount = {
    nom: "",
    prenom: "",
    telephone: "",
    whatsapp: "",
}

export const initUserPassword = {
    password: "",
    newpassword: "",
    confirmPassword: "",
}