import { AiFillHome } from 'react-icons/ai'
import { BiPlus } from 'react-icons/bi'
import { BsGridFill, BsFacebook } from 'react-icons/bs'
import { CiGrid2H } from 'react-icons/ci'
import { FaFilter } from 'react-icons/fa'
import { FiShoppingCart } from 'react-icons/fi'
import { HiOutlineMinusSm, HiMinusSm } from 'react-icons/hi'

import {
    MdExpandLess,
    MdShoppingCartCheckout,
    MdOutlineHelpOutline,
    MdShoppingCart,
    MdOutlineFavorite,
    MdExpandMore,
    MdModeEditOutline,
    MdDelete,
    MdWhatsapp
} from "react-icons/md"

const Icons = {
    AiFillHome,
    BiPlus,
    BsGridFill,
    BsFacebook,
    CiGrid2H,
    FaFilter,
    FiShoppingCart,
    HiMinusSm,
    HiOutlineMinusSm,
    MdDelete,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineHelpOutline,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp
};

export default Icons;