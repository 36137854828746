import { FC, useEffect, useState } from 'react';
import { RiMailOpenFill, RiInstagramFill } from 'react-icons/ri'
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import './FooterStyle.css'

const Footer: FC = () => {
  const [showFooter, setShowFooter] = useState(false);
  const { categories } = useCategoriesContext();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.pageYOffset;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= pageHeight - 20) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <MDBFooter className='text-center text-lg-start text-muted bg-footer ff-0 clr-blue ff-600'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom d-none'>
          {/* <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div> */}

          <div className='d-none'>
            <Link to='' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='facebook-f' />
            </Link>
            <Link to='' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='twitter' />
            </Link>
            <Link to='' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='google' />
            </Link>
            <Link to='' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='instagram' />
            </Link>
            <Link to='' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='linkedin' />
            </Link>
            <Link to='' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='github' />
            </Link>
          </div>
        </section>

        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5 pt-2'>
            <MDBRow className='mt-3'>
              <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                <h6 className='text-uppercase clr-blue ff-0 fw-bold mb-4'>
                  <MDBIcon color='secondary' icon='gem' className='' />
                  Beauty Hub
                </h6>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum iste praesentium minus quia beatae quibusdam error nobis modi possimus distinctio.
                </p>
              </MDBCol>

              <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                <h6 className='text-uppercase clr-blue ff-0 fw-bold mb-4'>Products</h6>
                {
                  categories.map((item, index) => (
                    <p key={index}>
                      <Link to={`/boutique/categorie/${item.slug}`} className='ff-0 fw-600 nav-link'>
                        {item.title}
                      </Link>
                    </p>
                  ))
                }


              </MDBCol>

              <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                <h6 className='text-uppercase clr-blue ff-0 fw-bold mb-4'>Useful links</h6>
                <p>
                  <Link to='/boutique/packs' className='ff-0 fw-600 nav-link'>
                    Packs
                  </Link>
                </p>
                <p>
                  <Link to='/my-account' className='ff-0 fw-600 nav-link'>
                    Account
                  </Link>
                </p>
                <p>
                  <Link to='/my-account/orders' className='ff-0 fw-600 nav-link'>
                    Commandes
                  </Link>
                </p>
                <p>
                  <Link to='/contact' className='ff-0 fw-600 nav-link'>
                    Support
                  </Link>
                </p>
              </MDBCol>

              <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase clr-blue ff-0 fw-bold mb-4'>Contact</h6>
                <p>
                  <MDBIcon color='secondary' icon='home' className='me-2' />
                  New York, NY 10012, US
                </p>
                <p>
                  <MDBIcon color='secondary' icon='envelope' className='me-3' />
                  info@beautyhub.com
                </p>
                <p>
                  <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
                </p>
                <p>
                  <MDBIcon color='secondary' icon='print' className='me-3' /> + 01 234 567 89
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='text-center p-4 copy-right-bg'>
          © {new Date().getFullYear()} Copyright
          <Link className='text-reset fw-bold ps-1' to='/'>
            Beauty Hub
          </Link>
        </div>
      </MDBFooter>
    </>
  );
};

export default Footer;