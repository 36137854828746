import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import checkoutImage from '../../../assets/images/checkout/promo-2-removebg-preview.png'
import emptyWishlist from '../../../assets/images/wishlist/empty-wishlist.png'
import { WishlistItem } from '../../components/WishlistItem/WishlistItem';
import FormInput from '../../components/FormInput/FormInput';
import * as yup from "yup";
import { useFormik } from "formik";
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { useWishlist } from '../../contexts/WishlistContext';
import { Link, useNavigate } from 'react-router-dom';
import { useApiRequest } from '../../helpers/ApiRequest';
import { InfoNotify, notify, ErrorNotify } from '../../helpers/Toastify';
import { BsCheckCircleFill, BsCircle, BsPatchCheck, BsStars } from 'react-icons/bs'
import { Helmet } from 'react-helmet-async';
import { useWindowWidthContext } from '../../contexts/WindowWidthProvider';
import { AuthUserProps } from '../../helpers/Props';
import './Wishlist.css';
import Footer from '../../components/Footer/Footer';

const Wishlist: React.FC = () => {

    const user_token = localStorage.getItem('user_token');
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState('livraison');
    const { wishlistItems, sousTotal, wishlistQuantity, setOrderData } = useWishlist()
    const [CPName, setCPName] = useState('');
    const [CPDiscount, setCPDiscount] = useState(0);
    let [newTotal, setNewTotal] = useState(sousTotal);
    const [codePromoState, setCodePromoState] = useState(false);
    const apiRequest = useApiRequest();
    const today = new Date();
    const { windowWidth } = useWindowWidthContext()

    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
    const storedUser = localStorage.getItem('user');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser));
        }
    }, []);


    useEffect(() => {

        if (paymentMethod === 'carte') {
            if (codePromoState === true) {
                setNewTotal(sousTotal - (sousTotal * ((CPDiscount + 5) / 100)))
            }
            if (codePromoState === false) {
                setNewTotal(sousTotal - (sousTotal * 0.05))
            }
        }

        if (paymentMethod === 'livraison') {
            if (codePromoState === true) {
                setNewTotal(sousTotal - ((sousTotal * CPDiscount) / 100))
            }
            if (codePromoState === false) {
                setNewTotal(sousTotal)
            }
        }

        if (newTotal >= 300) {
            const order = {
                paymentMethod: paymentMethod,
                totalPrice: newTotal
            }

            setOrderData(order);
        }

        if (newTotal < 300) {
            if (paymentMethod === 'livraison') {
                const order = {
                    paymentMethod: paymentMethod,
                    totalPrice: newTotal + 50
                }

                setOrderData(order);
            }

            if (paymentMethod === 'carte') {
                const order = {
                    paymentMethod: paymentMethod,
                    totalPrice: newTotal
                }

                setOrderData(order);
            }

        }

    }, [CPDiscount, sousTotal, codePromoState, newTotal, paymentMethod])


    return (
        <>
            <Helmet>
                <title>Wishlist</title>
            </Helmet>
            <TopHeader />
            <Navbar />

            <div className="wishlist d-flex justify-content-betdwen align-items-center">
                <div className="container product-cdontainer">
                    <HeaderContainer className='fw-bold fs-1 mt-5' title="Mes Favoris" />
                    {
                        wishlistQuantity < 1 ?
                            <>
                                <div className="row pt-5">
                                    <div className="col-12 d-flex-center col-">
                                        <img src={emptyWishlist} alt="empty wishlist" className='wishlist-empty-image' />
                                    </div>
                                    <div className="col-12 mt-3 d-flex-center col-">
                                        <h3>Votre list des favoris est actuellement vide.</h3>
                                    </div>
                                    <div className="col-12 mt-3 d-flex-center col-">
                                        <Link to='/boutique' className='nav-link back-to-boutique'>RETOUR À LA BOUTIQUE</Link>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={`row ${windowWidth < 578 ? 'px-3' : ''}`}>
                                    <div className="col-12 wishlist-items">
                                        <div className="items-list">
                                            {wishlistItems.map(item => (
                                                <WishlistItem key={item.product} {...item} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </div>

            {/* <Footer /> */}

        </>
    )
}

export default Wishlist;