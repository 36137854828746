import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { ProductProps } from '../helpers/Props';
import { useApiRequest } from '../helpers/ApiRequest';
import { apiUrl } from '../helpers/Helpers';

const BestSellerContext = createContext<{
    products: ProductProps[];
    fetchNextPage?: () => void;
    isFetchingNextPage?: boolean;
    minPrice: number;
    maxPrice: number;
    totalProducts: number;
    reactQueryLoadingData: boolean;
}>({
    products: [],
    fetchNextPage: () => null,
    isFetchingNextPage: false,
    minPrice: 0,
    maxPrice: 0,
    totalProducts: 0,
    reactQueryLoadingData: false,
});

export const useBestSellerContext = () => useContext(BestSellerContext);

export const BestSellerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [totalProducts, setTotalProducts] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const apiRequest = useApiRequest()

    const {
        data,
        isLoading: reactQueryLoadingData,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<ProductProps[], Error>(
        'products',
        async ({ pageParam = 1 }) => {
            const response = await axios.get(`${apiUrl}/products.json`, {
                params: {
                    bestseller: 'yes',
                    disponibilite: 'enstock',
                    page: pageParam,
                    itemsPerPage: 10
                },
            }
            )
            return response.data;
        },
        {
            getNextPageParam: (lastPage, pages) => pages.length + 1,
        }
    );

    const getTotalProducts = async () => {
        try {
            const response = await apiRequest({
                route: 'total-bestseller',
                method: 'GET',
            });

            if (response.status === 200) {
                setTotalProducts(response.data.total_products.totalProducts);
                setMinPrice(response.data.total_products.minPrice);
                setMaxPrice(response.data.total_products.maxPrice);

            }

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getTotalProducts();
    }, [])

    const products = data?.pages.flat() || [];

    return (
        <BestSellerContext.Provider value={{
            products,
            fetchNextPage,
            isFetchingNextPage,
            minPrice: Number(minPrice),
            maxPrice: Number(maxPrice),
            totalProducts: totalProducts,
            reactQueryLoadingData: reactQueryLoadingData
        }}>
            {children}
        </BestSellerContext.Provider>
    );
};