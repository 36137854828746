import React, { FC, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo/beautyhub-logo.png';
import emptyCart from '../../../assets/images/checkout/empty-cart.png'
import { FiShoppingCart, FiSearch } from 'react-icons/fi';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { BsSearch } from 'react-icons/bs';
import { SubShoppingCart } from '../SubShoppingCart/SubShoppingCart';
import { MdShoppingCartCheckout, MdOutlineHelpOutline } from 'react-icons/md';
import { useWindowWidthContext } from '../../contexts/WindowWidthProvider'
import { useMarquesContext } from '../../contexts/MarquesContext';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { useApiRequest } from '../../helpers/ApiRequest';
import { ProductProps } from '../../helpers/Props';
import ProductCard from '../ProductCard/ProductCard';
import Modal from '../DetailsProductModal/ModalContent';
import emptyBox from '../../../assets/images/bootique/empty-box.png'
import { useProductIds } from '../../hooks/useProductIds';
import useClickOutside from '../../hooks/useClickOutside';
import OffCanvasRight from '../OffCanvasRight/OffCanvasRight';
import { ErrorNotify } from '../../helpers/Toastify';
import './NavBarStyle.css'
import { categorieImgUrl, marqueImgUrl } from '../../helpers/Helpers';

let timeout: NodeJS.Timeout;
let marquetTimeout: NodeJS.Timeout;
let categorietTimeout: NodeJS.Timeout;

const Navbar: FC = () => {

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
      clearTimeout(marquetTimeout);
      clearTimeout(categorietTimeout);
    };
  }, []);

  // Global
  const navigate = useNavigate();
  const apiRequest = useApiRequest()
  const { productIds, isProductIds } = useProductIds();

  // Contexts
  const { windowWidth } = useWindowWidthContext()
  const { marques } = useMarquesContext();
  const { categories } = useCategoriesContext();
  const { cartItems, updateCartItemsWithPromotions, cartQuantity, total } = useShoppingCart()
  const cartItemsQuantity = cartItems.length;

  // States
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMarqueDropdownOpen, setIsMarqueDropdownOpen] = useState(false);
  const [isCategorieDropdownOpen, setIsCategorieDropdownOpen] = useState(false);
  const [CIndex, setCIndex] = useState(0);
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [textValue, setTextValue] = useState('');

  // Use Click Outside
  const elementRef = useRef<HTMLDivElement>(null);
  const blockRef = useRef<HTMLDivElement>(null);

  // OffCanvas close
  const closeBtnRef = useRef<HTMLButtonElement | null>(null);
  const closeCBtnRef = useRef<HTMLButtonElement | null>(null);

  const closeOffCanvas = () => {
    if (closeBtnRef.current) {
      closeBtnRef.current.click();
    }
  };

  const closeCOffCanvas = () => {
    if (closeCBtnRef.current) {
      closeCBtnRef.current.click();
    }
  };

  useEffect(() => {
    updateCartItemsWithPromotions()
  }, [ShoppingCartBoxVisible])

  const handleShoppingCartBoxClose = () => {
    setShoppingCartBoxVisible(false)
  };

  useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef);


  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDropdownMouseEnter = () => {
    if (windowWidth > 992) {
      clearTimeout(timeout);
      setIsDropdownOpen(true);
    }
  };


  const handleDropdownMouseLeave = () => {
    if (windowWidth > 992) {
      timeout = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 200);
    }
  };

  const handleMarqueDropdownMouseEnter = () => {
    clearTimeout(marquetTimeout);
    setIsMarqueDropdownOpen(true);
  };

  const handleMarqueDropdownMouseLeave = () => {
    marquetTimeout = setTimeout(() => {
      setIsMarqueDropdownOpen(false);
    }, 200);
  };

  const handleCategorieDropdownMouseEnter = (index: number) => {
    clearTimeout(categorietTimeout);
    setIsCategorieDropdownOpen(true);
    setCIndex(index)
  };

  const handleCategorieDropdownMouseLeave = (index: number) => {
    categorietTimeout = setTimeout(() => {
      setIsCategorieDropdownOpen(false);
      setCIndex(index)
    }, 200);
  };

  const handleShoppingCartIconClick = () => {
    setShoppingCartBoxVisible(!ShoppingCartBoxVisible);
  };

  const openContact = () => {
    navigate('/contact')
  };
  const openAccount = () => {
    navigate('/my-account')
  };

  const searchProducts = async (text: string) => {
    try {
      const response = await apiRequest({
        route: `search-products/${text}`,
        method: 'GET',
      });

      if (response.status === 200) {
        setProducts(response.data)
      } else {
        //ErrorNotify('Something wrong, try again')
      }

    } catch (error) {
      //ErrorNotify('Something wrong, try again')
    }
  };

  useEffect(() => {
    if (!searchBoxVisible) {
      setProducts([])
      setTextValue('');
    }
  }, [searchBoxVisible])

  const handleSearchIconClick = () => {
    setSearchBoxVisible(!searchBoxVisible);
  };

  const marquesContent =
    <>
      <div className="row ox-hidden" >
        {marques.map((item, index) => (
          <React.Fragment key={item.slug}>
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 d-flex-center flex-wrap" onClick={() => setIsDropdownOpen(false)} >
              <div className="m-2 nav-item-image-container">
                <Link to={`/boutique/marque/${item.slug}`} onClick={closeOffCanvas} >
                  <img
                    src={`${marqueImgUrl}/${item.image}`}
                    alt={item.title}
                    className='nav-item-marque-image' />
                </Link>
              </div>

              <div className="nav-item-title text-center">
                <p className='ff-0 fw-600 m-0'>{item.title}</p>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <hr className='hr-tag' />
      <div className="row ps-3 justify-content-start" onClick={closeOffCanvas}>
        <Link to='/details-marques' className='btn display-all'>Toutes les marques</Link>
      </div>
    </>

  const categoriesContent =
    <>
      <div className="row">
        {categories.map((item, index) => (
          <React.Fragment key={item.slug}>
            {
              index < 8 ?
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 d-flex-center flex-wrap" onClick={() => setIsDropdownOpen(false)} key={item.slug}>
                  <div className="m-2 nav-item-image-container">
                    <Link to={`/boutique/categorie/${item.slug}`} onClick={closeCOffCanvas}>
                      <img
                        src={`${categorieImgUrl}/${item.image}`}
                        alt={item.title}
                        className='nav-item-image' />
                    </Link>
                  </div>

                  <div className="nav-item-title text-center">
                    <p className='ff-0 fw-600 m-0'>{item.title}</p>
                  </div>
                </div>
                : ''
            }
          </React.Fragment>
        ))}
      </div>
      <hr className='hr-tag' />
      <div className="row  ps-3  justify-content-start" onClick={closeCOffCanvas}>
        <Link to='/details-categories' className='btn display-all'>Toutes les catégories</Link>
      </div>
    </>


  return (
    <>
      <header className="header-nav w-100 d-flex-start pt-2">
        <div className="containerr w-100 mx-5 top-container">
          <div className="row justify-content-end ai-center">

            <div className="col-xxl-5 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <nav className="navbar navbar-expand-xxl navbar-right">
                <div className="container-fluid d-flex-start">

                  <div className="responsive-icon">
                    <button className={`navbar-toggler ${windowWidth < 992 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>

                  <div className="w-100 collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                      <li className="nav-item  dropdown ps-1">
                        <Link className="text-upper nav-link navbar-link" to="/boutique/best-seller">Best sellers</Link>
                      </li>

                      <li className="nav-item ps-1">
                        <Link className="text-upper nav-link navbar-link" to="/">Nouveauté</Link>
                      </li>

                      <li className="nav-item ps-1">
                        <Link className="text-upper nav-link navbar-link" to="/boutique/promotion">Promotion</Link>
                      </li>

                      {/* Start Marque */}
                      <li className="nav-item ps-1 dropdown"
                        onMouseEnter={handleMarqueDropdownMouseEnter}
                        onMouseLeave={handleMarqueDropdownMouseLeave}>
                        <Link className="text-upper nav-link navbar-link  dropdown-toggle" to="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          NOS MARQUES
                        </Link>
                        <ul className={`dropdown-menu ${windowWidth > 520 ? isMarqueDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                          {marques.map((item) => (
                            <li key={item.id}>
                              <Link className="dropdown-item text-cap" to={`/boutique/marque/${item.slug}`}>
                                {item.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      {/* End Marque */}

                      <li className="nav-item ps-0">
                        <Link className="text-upper nav-link navbar-link " to="/boutique">Boutique</Link>
                      </li>

                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-flex-center">
              <div className={`navbar-brand m-logo ${windowWidth < 992 ? 'ms3' : 'ms-0me-4'}`}>
                <Link to="/">
                  <img src={logo} alt="beautyhub" className='beautyhub beautyhub-logo' />
                </Link>
              </div>
            </div>

            <div className={`col-xxl-5 col-xl-5 col-lg-4 col-md-6 col-sm-8 col-5  text-${windowWidth > 578 ? 'end' : 'center'} dd-flex justify-content-end align-items-center`}>
              <nav className="navbar navbar-expand-xxl navbar-right">
                <div className="container-fluid d-flex-start">
                  <div className="w-100 collapse navbar-collapse" id="navbarNavDropdown">
                    {/* Start Marque */}
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {
                        categories.map((item, index) => (
                          <li className="nav-item ps-1 dropdown"
                            key={item.slug}
                            onMouseEnter={() => handleCategorieDropdownMouseEnter(index)}
                            onMouseLeave={() => handleCategorieDropdownMouseLeave(index)}>
                            <Link to={`/boutique/categorie/${item.slug}`} className="text-upper nav-link navbar-link  dropdowntoggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              {item.title}
                            </Link>
                            {
                              item.subCategory.length > 0 ?
                                <ul className={`dropdown-menu ${windowWidth > 520 ? isCategorieDropdownOpen && CIndex === index ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                  {item.subCategory.map((sub, index) => (
                                    <li key={sub.id}>
                                      <Link className="dropdown-item text-cap" to={`/boutique/categorie/${item.slug}/${sub.slug}`}>
                                        {sub.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                                :
                                null
                            }
                          </li>
                        ))
                      }
                      <li>
                        <div className="pe-4 pt-2" onClick={handleSearchIconClick}>
                          <BsSearch className="navbar-icon fs-3 ms-sm-2 navbar-icon-search" />
                        </div>
                      </li>
                    </ul>
                    {/* End Marque */}
                  </div>

                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* Start Search box */}
        <div className={`search-box ${searchBoxVisible ? 'show' : ''}`}>
          <div className="row">
            <div className="col-12">
              <div className="input-group justify-content-end">
                <input
                  type="text"
                  value={textValue}
                  className="form-controll search-box-input"
                  placeholder="Recherche de produits…"
                  onChange={(e) => {
                    setTextValue(e.target.value);
                    searchProducts(e.target.value);
                  }}

                />
                <div className="input-group-append">
                  <span className="input-group-textt">
                    <FiSearch className="search-box-icon" />
                  </span>
                </div>
              </div>

            </div>
            <hr className="hr-tag mt-3" />

            <div className="col-12 d-flex justify-content-center flex-wrap">
              {
                products.length > 0
                  ?
                  products.map((item) => (
                    <ProductCard key={item.id} item={item} openModal={openModal} productIdsObj={{ productIds, isProductIds }} />
                  ))

                  :
                  textValue === ''
                    ?
                    ''
                    :
                    <>
                      <div className="col-12 d-flex justify-content-center align-items-center col-">
                        <img src={emptyBox} alt="empty box" className='shopping-card-empty-box' />
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center col-">
                        <div className="clr-dark fw-600 ff-0 fs-4 mn-13">Aucun Produits</div>
                      </div>
                    </>

              }
            </div>
            {/* </div> */}
          </div>
        </div>
        {/* End Search box */}

      </header>
      {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}

    </>
  );
};

export default Navbar;