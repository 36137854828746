import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UserDashStyle.css'
import { BsDot } from 'react-icons/bs'
import { UserDashboard } from './UserDashboard';
import { Helmet } from 'react-helmet-async';

const Content: React.FC = () => {


    return (
        <>
            <Helmet>
                <title>Mon Compte</title>
            </Helmet>
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 bm-ms-4">
                <div className="dash-text margin-left">
                    {/* <h4 className='mb-5' >
                        Bonjour {user} (vous n’êtes pas {user} ? Déconnexion)
                    </h4> */}
                    <div className="dash-text-content ms-3">
                        <h5 className='mb-4'>
                            À partir du tableau de bord de votre compte :
                        </h5>
                        <div className="urls ms-3">
                            <Link to='orders' className='nav-link mb-2 urls-link'><BsDot className='fs-3' /> Vous pouvez visualiser vos commandes récentes</Link>
                            <Link to='edit-address' className='nav-link mb-2 urls-link'><BsDot className='fs-3' /> Gérer vos adresses de livraison et de facturation</Link>
                            <Link to='edit-account' className='nav-link mb-2 urls-link'><BsDot className='fs-3' /> Changer votre mot de passe et les détails de votre compte.</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


const DashContent: React.FC = () => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export { DashContent };