import React, { memo, useCallback, useEffect, useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';
import { HiMinusSm } from 'react-icons/hi';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { Link } from 'react-router-dom';
import { useApiRequest } from '../../helpers/ApiRequest';
import { CartItemProps } from '../../helpers/Props';
import './ShoppingCartItem.css'
import { useShoppingCartDataContext } from '../../contexts/ShoppingCartDataContext';
import { uploadUrl, variantIconUrl, variantImgUrl } from '../../helpers/Helpers';

const ShoppingCartItem: React.FC<CartItemProps> = ({ product, quantity, isvariant }) => {

  const {
    removeFromCart,
    incrementCartQuantity,
    decrementCartQuantity,
    cartItems
  } = useShoppingCart()

  // const { item, productPrice } = usePromotionData(product);

  // if (!item) return null;


  const { products, packs } = useShoppingCartDataContext();

  const [discount, setDiscount] = useState(null);
  const apiRequest = useApiRequest();


  const mergedArray = [...products, ...packs];

  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)

  const fetchPromotionData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `promotion-discount/product_id/${item.id}`,
          method: 'GET',
        });

        setDiscount(response.data.discount)
      }

    } catch (error) {

    }
  }, [item?.id]);


  useEffect(() => {
    fetchPromotionData();
  }, [item?.id])

  if (item == null) return null

  let productPrice = discount !== null ? (item.sellingPrice - (item.sellingPrice * (discount / 100))) : item.sellingPrice;


  return (
    <>
      <div className="shopping-card-item d-flex justify-content-betdween align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center shopping-card-item-content">
            <div className="col-lg-2 col-md-2 col-sm-5 col-5 d-flex align-items-center shopping-card-item-image">
              <Link to={`${item.itemType === 'pack' ? `/boutique/pack/${item.slug}` : `/produit/${item.slug}`}`} className='d-inline-flex h-100'>
                <img src={`${uploadUrl}/${item.itemType === 'pack' ? 'pack' : 'product'}_images/${item.image}`} alt={item.title} className="rounded shopping-card-item-img" />
              </Link>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-7 col-7 d-flex align-items-center">
              <div className="shopping-card-item-details">
                <div className="shopping-card-item-title text-cap">{item.title}</div>
                {
                  isvariant ?
                    <div className="d-flex ai-center">
                      <div className={`subvariant-icon-content mx-1 d-flex-center c-pointer selected-border`} >
                        <div className="subvariant-icon d-flex-center">
                          <img src={`${variantIconUrl}/${item.variant[0].icon}`} alt={item.variant[0].label} className='variant-icon-img' />
                        </div>
                      </div>
                      <div className="subvariant-title ff-0">
                      {`${item.variant[0].reference} ${item.variant[0].label}`}
                      </div>
                    </div>
                    :
                    null
                }
                <div className="shopping-card-item-prix clr-dark">{item.itemType === 'pack' ? item.sellingPrice.toFixed(2) : productPrice.toFixed(2)} DH</div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex align-items-center">
              <div className="shopping-card-item-quantity align-items-center text-center">
                <span className="align-items-center fs-4" onClick={quantity > 1 ? () => decrementCartQuantity(item.itemType === 'pack' ? item.uniqId : item.id) : undefined}>
                  <HiMinusSm className='shopping-card-item-quantity-icon' />
                </span>
                <span className="align-items-center px-2 shopping-card-item-quantity-value user-select-none">{quantity}</span>
                <span className="align-items-center fs-4" onClick={() => incrementCartQuantity(item.itemType === 'pack' ? item.uniqId : item.id, item.itemType === 'pack' ? item.sellingPrice : productPrice)}>
                  <BiPlus className='shopping-card-item-quantity-icon' />
                </span>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex align-items-center">
              <div className="shopping-card-item-total align-items-center">
                <span className="align-items-center user-select-none px-">{item.itemType === 'pack' ? item.sellingPrice.toFixed(2) : (productPrice * quantity).toFixed(2)} DH</span>
              </div>
            </div>

            <div className="col-lg-1 col-md-1 col-sm-2 col-2 d-flex align-items-center">
              <div className="trash-icon">
                <BsFillTrashFill className="trash" onClick={(() => removeFromCart(item.itemType === 'pack' ? item.uniqId : item.id))} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MemoizedShoppingCartItem = memo(ShoppingCartItem);

export { MemoizedShoppingCartItem as ShoppingCartItem };