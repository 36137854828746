import React from 'react';
import { Rating } from 'react-simple-star-rating'
import './ReviewsStyle.css'

interface ReviewItemProps {
  name: string;
  comment: string;
  rating: number;
  avatar?: string;
  dateCreate?: string
}

const ReviewItem: React.FC<ReviewItemProps> = ({ name, comment, rating }) => {
  return (
    <>
      {/* <div className="col-12"> */}
      <div className="col-xl-5 col-lg-7 col-md-10 col-12">

      </div>

      <div className="col-xl-5 col-lg-7 col-md-10 col-12">
        <div className="d-flex justify-content-between">
          {/* <div className="avatar-image">
          <img src={avatar} alt="" className="review-avatar" />
        </div> */}
          <div className="review-content mt-2">
            <p className="fw-bold">{name}</p>
          </div>
          <div className="review-rating ms-5">
            <Rating
              initialValue={rating}
              readonly
            />
          </div>
        </div>
      </div>

      <div className="col-xl-5 col-lg-7 col-md-10 col-12">
        <div className="review-content mt-2">
          <p>{comment}</p>
        </div>
      </div>

      <div className="col-xl-5 col-lg-7 col-md-10 col-12">
        <hr className="hr-tag" />
      </div>

    </>
  );
};

export default ReviewItem