import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { toast } from 'react-toastify';
import { InfoNotify } from "../helpers/Toastify";
import { useNavigate } from "react-router-dom";

type WishlistProviderProps = {
  children: ReactNode
}

type WishlistItem = {
  user: number | string | undefined,
  product: number | string,
  pack?: number | string
  quantity: number,
  price: number,
  itemtype?: string
}

interface OrderContextType {
  paymentMethod: string;
  totalPrice: number;
}

type WishlistContext = {
  getItemQuantity: (product: number | string) => number
  incrementWishlistQuantity: (user: number | string | undefined, product: number | string, price: number) => void
  decrementWishlistQuantity: (user: number | string | undefined, product: number | string) => void
  removeFromWishlist: (product: number | string) => void
  addToWishlist: (user: number | string | undefined, product: number | string, price: number, itemtype?: string, quantity?: number) => void
  emptyWishlist: () => void;
  wishlistQuantity: number
  wishlistItems: WishlistItem[],
  sousTotal: number;
  orderData: OrderContextType;
  setOrderData: React.Dispatch<React.SetStateAction<OrderContextType>>;
}


const WishlistContext = createContext({} as WishlistContext)

export function useWishlist() {
  return useContext(WishlistContext)
}

export function WishlistProvider({ children }: WishlistProviderProps) {
  const [wishlistItems, setWishlistItems] = useLocalStorage<WishlistItem[]>(
    "wishlist",
    []
  )
  const [orderData, setOrderData] = useState<OrderContextType>({
    paymentMethod: '',
    totalPrice: 0,
  });

  // const user_token = localStorage.getItem('user_token');
  // const navigate = useNavigate();

  // if (!user_token) {
  //   navigate('/login');
  //   InfoNotify('Vous devrez être connecté pour effectuer cette action!');
  // }


  const wishlistQuantity = wishlistItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const [sousTotal, setSousTotal] = useState(0);

  useEffect(() => {
    const newTotal = wishlistItems.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );
    setSousTotal(newTotal);
  }, [wishlistItems]);


  const notifySuccess = (entity: string) => {
    toast.success(`${entity} ajouté avec succès`);
  };

  const notifyRemove = (entity: string) => {
    toast.success(`${entity} supprimé avec succès`);
  };


  function getItemQuantity(product: number | string) {
    return wishlistItems.find(item => item.product === product)?.quantity || 0
  }

  function addToWishlist(user: number | string | undefined, product: number | string, price: number, itemtype?: string, quantity: number = 1) {
    const pType = typeof (product);
    setWishlistItems((currItems) => {
      const existingItem = currItems.find((item) => item.product === product);
      if (existingItem) {
        return currItems.map((item) =>
          item.product === product ? { ...item, quantity: item.quantity + quantity, itemtype: itemtype } : item
        );
      } else {
        return [...currItems, { user, product, quantity, price, itemtype }];
      }
    });
    notifySuccess(`${pType === 'string' ? 'Pack' : 'Produit'}`);
  }

  function incrementWishlistQuantity(user: number | string | undefined, product: number | string, price: number) {
    setWishlistItems(currItems => {
      if (currItems.find(item => item.product === product) == null) {
        return [...currItems, { user, product, quantity: 1, price }]
      } else {
        return currItems.map(item => {
          if (item.product === product) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function decrementWishlistQuantity(user: number | string | undefined, product: number | string) {
    setWishlistItems(currItems => {
      if (currItems.find(item => item.product === product)?.quantity === 1) {
        return currItems.filter(item => item.product !== product)
      } else {
        return currItems.map(item => {
          if (item.product === product) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function removeFromWishlist(product: number | string) {
    const pType = typeof (product);
    setWishlistItems(currItems => {
      return currItems.filter(item => item.product !== product)
    })
    notifyRemove(`${pType === 'string' ? 'Pack' : 'Produit'}`);
  }

  function emptyWishlist() {
    setWishlistItems([]);
  }

  return (
    <WishlistContext.Provider
      value={{
        getItemQuantity,
        addToWishlist,
        incrementWishlistQuantity,
        decrementWishlistQuantity,
        removeFromWishlist,
        emptyWishlist,
        wishlistItems,
        wishlistQuantity,
        sousTotal,
        orderData,
        setOrderData
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}