import React, { useRef, useState } from 'react'
import { ImPhone } from 'react-icons/im'
import { RiMailOpenFill, RiInstagramFill } from 'react-icons/ri'
import { FiShoppingCart } from 'react-icons/fi';
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import socialMedia from '../../../assets/images/social/social-media-1.png'
import { useWindowWidthContext } from '../../contexts/WindowWidthProvider'
import { Link } from 'react-router-dom'
import useClickOutside from '../../hooks/useClickOutside'
import freeShipping from '../../../assets/images/shipping/free-shipping.png'
import './TopHeader.css'
import { SubShoppingCart } from '../SubShoppingCart/SubShoppingCart';
import { ProductProps } from '../../helpers/Props';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import emptyCart from '../../../assets/images/checkout/empty-cart.png'
import { MdShoppingCartCheckout, MdShoppingCart, MdOutlineFavorite, MdOutlineHelpOutline } from 'react-icons/md';
import { useWishlist } from '../../contexts/WishlistContext';
import { HiOutlineUserCircle } from 'react-icons/hi';

const TopHeader: React.FC = () => {
    const { windowWidth } = useWindowWidthContext()
    const [isSocialMedia, setIsSocialMedia] = useState(false)

    const { cartItems, cartQuantity, total, emptyShoppingCart } = useShoppingCart()
    const { wishlistItems, wishlistQuantity, sousTotal } = useWishlist()
    const cartItemsQuantity = cartItems.length;
    const wishlistItemsQuantity = wishlistItems.length;

    // States
    const [searchBoxVisible, setSearchBoxVisible] = useState(false);
    const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMarqueDropdownOpen, setIsMarqueDropdownOpen] = useState(false);
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [textValue, setTextValue] = useState('');

    // Use Click Outside
    // Use Click Outside
    const elementRef = useRef<HTMLDivElement>(null);
    const blockRef = useRef<HTMLDivElement>(null);

    const handleShoppingCartBoxClose = () => {
        setShoppingCartBoxVisible(false)
    };

    useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef);

    const handleShoppingCartIconClick = () => {
        setShoppingCartBoxVisible(!ShoppingCartBoxVisible);
    };


    return (
        <div className='top-header-container d-flex justify-content-between align-items-center'>
            <div className="containerr top-container w-100 mx5">
                <div className="row align-items-center mx-3">
                    <div className={`col-lg-3 col-md-3 col-sm-7 col-10 align-items-center ${windowWidth < 770 ? 'textcenter' : 'text-start d-inline-flex ps-4'} ${windowWidth < 578 ? 'dcontents' : ''} `}>
                        <div className="info d-inline-flex m-info mb-1">
                            <Link to='tel:0661297515' className={`nav-link top-header-text ${windowWidth < 578 ? 'me-2' : 'me-4'}`}><ImPhone className='icon' /> 0661297515</Link>
                        </div>
                    </div>

                    <div className={`col-lg-6 col-md-6 col-sm-6 col-12 align-items-center ${windowWidth < 770 ? 'text-center' : 'text-center d-inline-fle ps4'}`}>
                        <div className="d-flex-center">
                            <div className="free-shipping">
                                <img src={freeShipping} alt="free shipping" className='free-shipping-img me-2' />
                            </div>
                            <span className='clr-light'>Livraison grauite à partir de 300 DH</span>
                        </div>
                    </div>

                    <div className={`col-lg-3 col-md-3 col-sm-5 col-2 mt-3 align-items-center time`}>
                        <div className="">
                            <div className={`social-media-conten`}>
                                <div className="row"  >
                                    <div className="col-12 text-end d-flex-end">
                                        <div className="me-3">
                                            <Link to='/login' className='nav-link'>
                                                <HiOutlineUserCircle className="top-header-icon me" />
                                            </Link>
                                        </div>

                                        <div className="me-4">
                                            <Link to='/wishlist'><MdOutlineFavorite className="top-header-icon me" /></Link>
                                            <span className="position-absolute translate-middle badge card-badge rounded-circle bg-warning badge-card">
                                                {wishlistItemsQuantity}
                                            </span>
                                        </div>

                                        <div className="" onClick={handleShoppingCartIconClick} ref={blockRef}>
                                            <MdShoppingCart className="top-header-icon" />
                                            <span className="position-absolute translate-middle badge card-badge rounded-circle bg-danger badge-card">
                                                {cartItemsQuantity}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            {/* Start shoppingCart box */}
            <div ref={elementRef} className={`subshopping-card-box ${ShoppingCartBoxVisible ? 'show' : 'd-nones'}`}>
                <div className="container">
                    <div className="row card-content">
                        <div className="col-md-12 col-12 mb-3">

                            {
                                cartQuantity < 1 ?
                                    <>
                                        <div className="row">
                                            <div className="col-12 d-flex-center col-">
                                                <img src={emptyCart} alt="empty cart" className='subshopping-card-empty-image' />
                                            </div>
                                            <div className="col-12 mt-3 d-flex-center col-">
                                                <h4 className='text-center'>Votre panier est actuellement vide.</h4>
                                            </div>
                                            <div className="col-12 mt-3 d-flex-center col-">
                                                <Link to='/boutique' className='nav-link back-to-boutique mb-3'>RETOUR À LA BOUTIQUE</Link>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    cartItems.map(item => (
                                        <SubShoppingCart key={item.product} {...item} />
                                    ))
                            }

                        </div>
                    </div>

                    {cartQuantity > 0 && (
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="total-price text-center d-flex justify-content-between px-4">
                                    <span className="price-text mt-2">SOUS-TOTAL :</span>
                                    <span className="price-value mt-2">{total.toFixed(2)} DH</span>
                                </div>

                            </div>
                        </div>
                    )}

                </div>
                <div className="row">
                    {cartQuantity > 0 && (
                        <div className="mt-4 col-md-12 col-12 text-center card-checkout d-flex-center">
                            <Link to='/my-cart' className='card-checkout-btn mb-2 nav-link'>
                                <MdShoppingCartCheckout className='card-checkout-icon' />VOIR LE PANIER
                            </Link>
                        </div>
                    )}
                    <div className="subshopping-card-message text-center c-pointer" onClick={() => emptyShoppingCart()}>
                        Vider mon panier
                    </div>
                </div>
            </div>
            {/* End shoppingCart box */}
        </div>
    )
}

export default TopHeader;
