import { FC } from 'react';
import './ProductImagesGalleryStyle.css'
import ImageGallery from 'react-image-gallery';
import { ProductProps } from '../../../helpers/Props';
import { PMultiImgUrl, VMultiImgUrl } from '../../../helpers/Helpers';

interface ProductImagesGalleryProps {
  product: ProductProps;
  isVariant?: boolean
  index?: number
}

const ProductImagesGallery: FC<ProductImagesGalleryProps> = ({ product, isVariant = false, index = 0 }) => {

  const productImages = product
    ? product.productImages.map(image => ({
      original: `${PMultiImgUrl}/${image.image}`,
      thumbnail: `${PMultiImgUrl}/${image.image}`,
    }))
    : [];

  const variantImages = product
    ? product.variant[index].variantImages.map(image => ({
      original: `${VMultiImgUrl}/${image.image}`,
      thumbnail: `${VMultiImgUrl}/${image.image}`,
    }))
    : [];

  if (!product) {
    return null;
  }

  return (
    <div>
      <ImageGallery
        items={isVariant ? variantImages : productImages}
        lazyLoad={true}
      />
    </div>
  );
};

export default ProductImagesGallery;