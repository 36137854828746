import React, { Suspense, useEffect, useState, lazy } from 'react'
import { useApiRequest } from '../../helpers/ApiRequest';
import Loading from '../../components/Loading/Loading';
import { Helmet } from 'react-helmet-async';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TopHeader = lazy(() => import('../../components/TopHeader/TopHeader'));
const Navbar = lazy(() => import('../../components/Navbar/Navbar'));
const SliderCarousel = lazy(() => import('../../components/Carousel/HomeCarousel/HomeCarousel'));
const Marques = lazy(() => import('../../components/Marques/Marques'));
const PopulareProducts = lazy(() => import('../../components/PopulareProducts/PopulareProducts'));
const Categories = lazy(() => import('../../components/Categories/Categories'));
const CategoriesOld = lazy(() => import('../../components/Categories/CategoriesOld'));
const Footer = lazy(() => import('../../components/Footer/Footer'));
const PopularePacks = lazy(() => import('../../components/PopularePacks/PopularePacks'));

interface SliderProps {
    id: number;
    title: string;
    image: string;
}

const Home: React.FC = () => {
    const [sliders, setSliders] = useState<SliderProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const apiRequest = useApiRequest()

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1300);
    }, []);

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: 'sliders.json',
                method: 'GET',
            });

            setSliders(response.data)
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])


    useEffect(() => {
        AOS.init();
    }, [])


    return (

        <>
            <Helmet>
                <title>Beauty Hub - Votre atout beauté</title>
            </Helmet>
            {isLoading ? (
                <Loading />
            ) : (
                <Suspense fallback={<Loading />}>
                    <TopHeader />
                    <Navbar />
                    <SliderCarousel items={sliders} />
                    {/* <Marques /> */}
                    {/* <PopulareProducts /> */}
                    {/* <PopularePacks /> */}
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <Marques />
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <PopulareProducts />
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <PopularePacks />
                    </div>
                    {/* <div data-aos="fade-up" data-aos-duration="2000">
                        <CategoriesOld />
                    </div> */}
                    {/* <div data-aos="fade-up" data-aos-duration="2000">
                        <Categories />
                    </div> */}
                    <Footer />
                </Suspense>
            )}
        </>
    )
}

export default Home;